<template>
  <el-popover
    placement="bottom"
    width="720"
    trigger="hover"
    @show="getLocalOrderStat"
    @hide="onHide"
    :disabled="disabled || level == 'ad'"
  >
    <localOrderPop
      v-if="show"
      :level="level"
      :id="_id"
      :data="data"
      v-loading="loading"
    ></localOrderPop>
    <div slot="reference">
      <slot></slot>
    </div>
  </el-popover>
</template>

<script>
import localOrderPop from './localOrderPop';
export default {
  props: {
    id: String,
    level: String,
    row: Object,
    api: Function,
    platform: String,
    params: Object,
    disabled: Boolean,
  },
  components: { localOrderPop },
  data() {
    return {
      localOrderStat: {},
      loading: false,
      show: false,
    };
  },
  computed: {
    data() {
      if (!this.api) {
        return this.row;
      }
      return this.localOrderStat[this._id];
    },
    _id() {
      if (this.id) return this.id;
      if (this.level == 'campaign') {
        return this.row.ad.campaignId;
      } else {
        return this.row.ad.adGroupId;
      }
    },
  },
  methods: {
    getLocalOrderStat() {
      this.show = true;

      if (this.localOrderStat[this._id] || this.localOrderStat[this._id] === null || !this.api) {
        return;
      }
      let params = {
        ...this.params,
        actId: this.row.ad.adAccountId,
        platform: this.platform,
        level: this.level,
        daysDiff: 7, // 统计时间天数，近7天传7  近15天传15
        ids: [this._id], //广告id  如果广告层级是系列传系列id，如果是组传组id
      };
      this.loading = true;
      this.api(params)
        .then((res) => {
          this.localOrderStat = {
            ...res.data,
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onHide() {
      this.show = false;
    },
  },
};
</script>
