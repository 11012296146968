<template>
  <div>
    <div
      class="profitRateResult"
      v-if="adProductReport"
    >
      <el-popover
        placement="top"
        title=""
        width="880"
        trigger="hover"
        @show="setActiveTab"
      >
        <el-tabs
          v-model="activeTab"
          type="border-card"
          @tab-click="getMultiData"
        >
          <el-tab-pane
            name="adProductReport"
            :label="(level == 'campaign' ? '广告系列层级' : '组') + '经营分析'"
          >
            <productReportCard
              :productReport="adProductReport"
              :platform="platform"
              :dateRange="dateRange"
            ></productReportCard>
          </el-tab-pane>
          <el-tab-pane
            name="dataPane"
            label="数据看板"
            :disabled="level !== 'campaign' || disabledDataPane"
          >
            <data-board
              :multiData="multiData"
              ref="dataBoard"
              v-if="activeTab == 'dataPane'"
              v-loading="loading"
            ></data-board>
          </el-tab-pane>
        </el-tabs>
        <div slot="reference">
          <div class="txt_img">
            <img
              :src="imgSrc[adProductReport.profitRateResult]"
              alt="经营分析"
            />
          </div>
        </div>
      </el-popover>
    </div>
    <div v-else>--</div>
  </div>
</template>

<script>
import productReportCard from './productReportCard.vue';
import dataBoard from './dataBoard';
import HIGHT from './icon/level1.png';
import MID from './icon/level2.png';
import LOW from './icon/level3.png';
import LOW2 from './icon/level4.png';
import { multipleAnalysis } from '@/api/adManagement';
export default {
  components: {
    productReportCard,
    dataBoard,
  },
  props: {
    adProductReport: Object, // 经营分析
    level: String, // 层级
    campaignId: String, // 系列id 数据看板接口需要
    currency_sign: String, // 币种 数据看板接口需要
    platform: String, // 平台 数据看板接口需要
    adAccountId: String, // 账户 数据看板接口需要
    disabledDataPane: Boolean, // 禁用数据看板
    dateRange: Array,
  },
  data() {
    return {
      activeTab: 'adProductReport',
      imgSrc: {
        HIGHT,
        MID,
        LOW,
        LOW2,
      },
      multiData: {},
      loading: false,
      // dateRange:
      //   this.platform == 'facebook'
      //     ? JSON.parse(localStorage.getItem('adDateRange'))
      //     : JSON.parse(localStorage.getItem('batchAdDateRange')),
    };
  },
  computed: {
    paramsData() {
      return {
        campaignId: this.campaignId,
        currency_sign: this.currency_sign,
        platform: this.platform,
        adAccountId: this.adAccountId,
      };
    },
  },
  methods: {
    keyFlag() {
      let key = 'adProductReport';
      return key;
    },
    setActiveTab() {
      this.activeTab = this.keyFlag();
      // this.dateRange = JSON.parse(localStorage.getItem('adDateRange'));
    },
    // 获取数据看板数据
    getMultiData(v) {
      if (v.name == 'dataPane') {
        this.$nextTick(() => {
          // this.$refs.dataBoard.index = 1;
          this.loading = true;
          multipleAnalysis(JSON.stringify(this.paramsData)).then((res) => {
            this.loading = false;
            this.multiData = res.data;
          });
        });
      }
    },
  },
  mounted() {
    this.activeTab = this.keyFlag();
  },
};
</script>

<style lang="scss" scoped>
.txt_img {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 25px;
    height: 25px;
    margin-left: 5px;
  }
}
</style>
