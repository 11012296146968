<template>
  <div>
    <div
      id="echarts"
      ref='echarts'
      style="width: 680px; height: 200px"
    ></div>
    <el-table
      :data="dailyStatsDesc"
      border
      max-height="260"
      style="font-size: 12px; width: 100%"
      v-if="level !== 'ad'"
    >
      <el-table-column
        property="dateStr"
        label="日期"
        width="90"
      ></el-table-column>
      <el-table-column
        property="validSaleOrderCount"
        label="商品订单"
        v-if="level == 'campaign'"
      >
        <template slot-scope="scope"> {{ scope.row.vso }}/{{ scope.row.so }} </template>
      </el-table-column>
      <el-table-column
        property="ds"
        label="每日花费"
      >
        <template slot-scope="scope">
          {{ scope.row.ds && scope.row.ds.toFixed(2) }}
        </template>
      </el-table-column>
      <el-table-column
        property="vlos"
        label="有效订单花费"
      >
        <template slot-scope="scope">
          {{ scope.row.vlos && scope.row.vlos.toFixed(2) }}
        </template>
      </el-table-column>
      <el-table-column
        property="name"
        label="优化师订单"
        width="90"
        v-if="level == 'campaign'"
      >
        <template slot-scope="scope"> {{ scope.row.voo }}/{{ scope.row.oo }} </template>
      </el-table-column>
      <el-table-column
        property="address"
        label="本地订单"
      >
        <template slot-scope="scope"> {{ scope.row.vlo }}/{{ scope.row.lo }} </template>
      </el-table-column>
      <el-table-column
        property="address"
        label="自然订单"
        v-if="level == 'campaign'"
      >
        <template slot-scope="scope"> {{ scope.row.vno }}/{{ scope.row.no }} </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    level: String,
    data: Object,
  },
  data() {
    return {};
  },
  computed: {
    dailyStatsDesc() {
      if (!this.data) return [];
      let d = [];
      for (let i = this.dailyStats.length; i > 0; i--) {
        d.push(this.dailyStats[i - 1]);
      }
      return d;
    },
    dailyStats() {
      return (this.data && this.data.dailyStats) || [];
    },
  },
  methods: {
    drawEcharts() {
      var chartDom = this.$refs.echarts;
      if (!chartDom) return;
      var myChart = this.$echarts.init(chartDom);

      var option;
      option = {
        title: {
          // text: "订单量展示",
        },
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          data:
            this.level == 'campaign'
              ? [
                  '本地订单',
                  '优化师订单',
                  '自然流量订单',
                  '商品订单',
                  '本地有效订单',
                  '优化师有效订单',
                  '自然流量有效订单',
                  '商品有效订单',
                ]
              : ['本地订单'],
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.dailyStats.map((v) => v.dateStr),
          axisLabel: {
            // rotate: 30,
            fontSize: 10
          }
        },
        yAxis: {
          type: 'value',
        },
        series:
          this.level == 'campaign'
            ? [
                {
                  name: '本地订单',
                  type: 'line',
                  // stack: "总量",
                  data: this.dailyStats.map((v) => v.lo),
                },
                {
                  name: '本地有效订单',
                  type: 'line',
                  // stack: "总量",
                  data: this.dailyStats.map((v) => v.vlo),
                },
                {
                  name: '优化师订单',
                  type: 'line',
                  // stack: "总量",
                  data: this.dailyStats.map((v) => v.oo),
                },
                {
                  name: '优化师有效订单',
                  type: 'line',
                  // stack: "总量",
                  data: this.dailyStats.map((v) => v.voo),
                },
                {
                  name: '自然流量订单',
                  type: 'line',
                  // stack: "总量",
                  data: this.dailyStats.map((v) => v.no),
                },
                {
                  name: '自然流量有效订单',
                  type: 'line',
                  // stack: "总量",
                  data: this.dailyStats.map((v) => v.vno),
                },
                {
                  name: '商品订单',
                  type: 'line',
                  // stack: "总量",
                  data: this.dailyStats.map((v) => v.so),
                },
                {
                  name: '商品有效订单',
                  type: 'line',
                  // stack: "总量",
                  data: this.dailyStats.map((v) => v.vso),
                },
              ]
            : [
                {
                  name: '本地订单',
                  type: 'line',
                  // stack: "总量",
                  data: this.dailyStats.map((v) => v.lo),
                },
              ],
      };
      myChart.setOption(option);
    },
  },
  watch: {
    data: {
      immediate: true,
      handler(val) {
        if (val) {
            this.$nextTick(()=> {
                this.drawEcharts();
            })
        }
      },
    },
  },
};
</script>
