<template>
  <el-dialog
    title="保存为常用"
    :visible="show"
    width="600px"
    append-to-body
    @close="onClose"
  >
    <div>
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        label-width="80px"
        class="demo-ruleForm"
        :rules="rules"
        @submit.native.prevent
      >
        <el-form-item
          label="名称："
          prop="name"
        >
          <el-input v-model="ruleForm.name" @keyup.enter.native="saveBtn"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="onClose">取 消</el-button>
      <el-button
        type="primary"
        @click="saveBtn"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    params: {
      type: Object,
      default: () => ({}),
    },
    API: Function,
  },
  data() {
    return {
      ruleForm: {},
      rules: {
        name: [
          { required: true, message: '请输入兴趣标签名称', trigger: 'blur' },
          { min: 1, max: 100, message: '长度在 1 到 100 个字符', trigger: 'blur' },
        ],
      },
    };
  },
  methods: {
    onClose() {
      this.$emit('update:show', false);
      this.$emit('close');
    },
    saveBtn() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let params = {
            ...this.params,
            label: this.ruleForm.name,
          };
          this.$showLoading();
          this.API(params).then((res) => {
            this.$hideLoading();
            if (res.code == 0) {
              this.$message({
                type: 'success',
                message: '保存成功',
              });
              this.ruleForm.name = '';
              this.onClose();
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  },
};
</script>
